<template>
  <el-tabs v-loading="loading" v-model="activeName" type="border-card" style="min-height: 35rem">
    <el-tab-pane label="基础信息" name="basic">
      <basic v-model="form" @submit="submit"></basic>
    </el-tab-pane>
    <el-tab-pane label="商品详情" name="detail">
      <detail v-model="form" @submit="submit"></detail>
    </el-tab-pane>
    <el-tab-pane label="海报" name="poster">
      <poster v-model="form" @submit="submit"></poster>
    </el-tab-pane>
    <el-tab-pane v-if="form.id > 0" label="自定义表单" name="diy-form">
      <diyform></diyform>
    </el-tab-pane>
    <el-tab-pane v-if="form.type === 1 && form.id > 0" label="卡密" name="code">
      <cdkey></cdkey>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import basic from "@/view/plugin/shop/goods/edit/basic";
import detail from "@/view/plugin/shop/goods/edit/detail";
import poster from "@/view/plugin/shop/goods/edit/poster";
import diyform from "@/view/plugin/shop/goods/edit/form";
import cdkey from "@/view/plugin/shop/goods/edit/cdkey";
export default {
  name: "edit",
  components:{
    basic,
    poster,
    detail,
    diyform,
    cdkey,
  },
  data(){
    return{
      activeName:"basic",
      edit_id:false,
      loading:false,
      form:{
        merchant_id:0,
        type:0,
        cate_id:0,
        pic:"",
        title:"",
        desc:"",
        detail:"",
        stock:0,
        buy_limit:0,
        price:0,
        original_fee:0,
        buy_btn_txt:"",
        invite_award:0,
        invite_2_award:0,
        status:false,
        index_show:false,

        bg:"",
        qrcode_size:0,
        qrcode_left:0,
        qrcode_top:0,
        pic_size:0,
        pic_left:0,
        pic_top:0,
        title_left:0,
        title_top:0,
        title_size:0,
        title_color:"",

        time_end:"",
        time_start:"",
        tips:"",
        use_limit:0,
        total_use:0,
      },
    }
  },
  mounted() {
    if(this.$route?.query?.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  },
  methods:{
    load(){
      this.loading = true;
      this.$api.plugin.shop.goodsOne({id:this.edit_id}).then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    submit(){
      this.$api.plugin.shop.goodsEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.edit_id ? this.load() : this.$router.back();
      })
    },
  }
}
</script>

<style scoped>

</style>