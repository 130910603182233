<template>
  <div>
    <div class="flex-def flex-zEnd" style="margin-bottom: 1rem">
      <el-button @click="dialogTableVisible=true" type="primary" size="medium">添加</el-button>
    </div>
    <el-table v-loading="loading" :data="list" border style="width: 100%">
      <el-table-column prop="id" label="编号" width="80"></el-table-column>
      <el-table-column prop="sort" label="排序" width="80"></el-table-column>
      <el-table-column prop="title" label="名称"></el-table-column>
      <el-table-column label="类型">
        <template #default="s">{{parseTypeStr(s.row.type)}}</template>
      </el-table-column>
      <el-table-column label="选项" show-overflow-tooltip>
        <template #default="s">
          <div>{{s.row.type == 3 ?  "须选：" + s.row.must_choose + "项" : ""}}</div>
          <div>{{parseContent(s.row)}}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template #default="scope">
          <el-switch @change="statusChange(scope.row)" v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </template>
      </el-table-column>
      <el-table-column width="300" label="操作" align="center">
        <template #default="s">
          <el-button @click="editSort(s.row)" size="mini">修改排序</el-button>
          <el-button @click="editContent(s.row)" v-if="[3,2].includes(s.row.type)" size="mini">修改选项</el-button>
          <el-button @click="editDel(s.row)" type="danger" size="mini">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加表单项" :visible.sync="dialogTableVisible" width="48rem" @close="formClose">
      <div class="flex-def flex-zCenter">
        <el-form :model="form" label-width="3rem">
          <el-form-item label="名称">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="form.type">
              <el-radio border :label="1">文本输入</el-radio>
              <el-radio border :label="2">单选框</el-radio>
              <el-radio border :label="3">多选框</el-radio>
              <el-radio border :label="4">手机号</el-radio>
              <el-radio border :label="5">车牌号</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="[3,2].includes(form.type)" label="选项">
            <span class="y-desc">每行一条回车换行</span>
            <el-input type="textarea" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item v-if="form.type == 3" label="须选">
            <el-input-number v-model="form.must_choose"></el-input-number>项
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="formSubmit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="修改排序" :visible.sync="sortEditShow" @close="formClose">
      <el-form :model="form">
        <el-form-item label="排序-数字越大越靠前">
          <el-input v-model="form.sort"></el-input>
        </el-form-item>
        <el-form-item align="right">
          <el-button @click="formSubmit" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改选项" :visible.sync="contentEditShow" @close="formClose">
      <el-form :model="form">
        <el-form-item label="选项-每行一条">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item v-if="form.type == 3" label="须选">
          <el-input-number v-model="form.must_choose"></el-input-number>项
        </el-form-item>
        <el-form-item align="right">
          <el-button @click="formSubmit" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "edit_form",
  data(){
    return{
      dialogTableVisible:false,
      sortEditShow:false,
      contentEditShow:false,
      form:{
        goods_id:0,
        sort:1,
        title:"",
        type:1,
        content:"",
      },
      list:[],
      edit_id:0,
      loading:false,
    }
  },
  mounted() {
    this.edit_id = parseInt(this.$route.query.id);
    this.load();
  },
  methods:{
    editDel(row){
      this.$api.plugin.shop.goodsFormDel({
        id:row.id
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    editContent(row){
      this.form = {
        ...row
      }
      this.contentEditShow = true;
    },
    editSort(row){
      this.form = {
        ...row
      }
      this.sortEditShow = true;
    },
    statusChange(row){
      this.form = {
        ...row
      }
      this.formSubmit();
    },
    formClose(){
      this.form = {
        sort:1,
        title:"",
        type:1,
        content:"",
      }
      this.dialogTableVisible = false;
      this.sortEditShow = false;
      this.contentEditShow = false;
    },
    formSubmit(){
      this.form.goods_id = this.edit_id;
      this.form.sort = parseInt(this.form.sort);
      this.$api.plugin.shop.goodsFormEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.formClose();
        this.load();
      })
    },
    load(){
      this.loading = true;
      this.$api.plugin.shop.goodsFormAll({
        id:this.edit_id
      }).then(res=>{
        this.loading = false;
        this.list = res;
      });
    },
    parseContent(item){
      return item.content;
    },
    parseTypeStr(type){
      let str = "未知";
      switch (parseInt(type)){
        case 1:
          str = "文本输入";
          break;
        case 2:
          str = "单选框";
          break;
        case 3:
          str = "多选框";
          break;
        case 4:
          str = "手机号";
          break;
        case 5:
          str = "车牌号";
          break;
      }
      return str;
    }
  }
}
</script>

<style scoped>

</style>