<template>
  <div>
    <y_editor :eid="form.id + 'goods-detail'" v-model="form.detail"></y_editor>
    <div class="flex-def flex-zEnd" style="width: 100%;margin-top: 1rem">
      <el-button type="primary" @click="submit">保存</el-button>
      <el-button @click="$router.back()">返回列表</el-button>
    </div>
  </div>
</template>

<script>
import y_editor from "@/components/editor/y_editor";
export default {
  name: "detail",
  components:{
    y_editor,
  },
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{
          detail:"",
        }
      }
    },
  },
  watch:{
    modelVal(){
      this.form = this.modelVal;
    }
  },
  data(){
    return{
      form:this.modelVal,
    }
  },
  mounted() {
  },
  methods:{
    submit(){
      this.$emit("submit")
    },
  }
}
</script>

<style scoped>

</style>