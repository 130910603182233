<template>
  <div>
    <div class="flex-def" style="padding: 2rem">
      <div class="panel-shadow poster-con" :style="`background-image:url(${form.bg ? $store.state.setting.uni_attachment_url[$route.params.uni_acid] : ''}${form.bg})`">
        <div v-drag="that" data-pre="qrcode" :draggable="false" @dragover="qrcodeDrag" :style="qrcodeStyle" class="y-pointer flex-def flex-zCenter flex-cCenter poster-qrcode">二维码</div>
        <div v-drag="that" data-pre="pic" :style="picStyle" class="y-pointer flex-def flex-zCenter flex-cCenter poster-pic">缩略图</div>
        <div v-drag="that" data-pre="title" :style="titleStyle" class="y-pointer flex-def flex-zCenter flex-cCenter poster-title">用户标题标题标题</div>
      </div>
      <div style="margin-left: 2rem">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="海报背景图片">
            <span style="font-size: 9px;color: #888888">海报尺寸640*1008</span>
            <yUploadImg v-model="form.bg"></yUploadImg>
          </el-form-item>
          <el-form-item label="缩略图大小">
            <el-input-number v-model="form.pic_size"></el-input-number>
          </el-form-item>
          <el-form-item label="二维码大小">
            <el-input-number v-model="form.qrcode_size"></el-input-number>
          </el-form-item>
          <el-form-item label="标题字号">
            <el-input-number v-model="form.title_size"></el-input-number>
          </el-form-item>
          <el-form-item label="标题颜色">
            <yColorChoose v-model="form.title_color"></yColorChoose>
          </el-form-item>
          <el-form-item>
            <el-button @click="formSubmit" type="primary" >保存</el-button>
            <el-button v-if="form.id > 0" @click="clearCache" type="info" >清空海报缓存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import yUploadImg from "@/components/y_upload_img";
import yColorChoose from "@/components/y_color_choose";
export default {
  name: "poster",
  components: {
    yUploadImg,
    yColorChoose
  },
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{
          bg:"",
          qrcode_size:0,
          qrcode_left:0,
          qrcode_top:0,
          pic_size:0,
          pic_left:0,
          pic_top:0,
          title_left:0,
          title_top:0,
          title_size:0,
          title_color:"",
        }
      }
    },
  },
  watch:{
    modelVal(){
      this.form = this.modelVal;
    }
  },
  directives: {
    drag(el,App){
      let oDiv = el;
      document.onselectstart = function() {
        return false;
      };
      oDiv.onmousedown = function(e){
        let disX = e.clientX - oDiv.offsetLeft;
        let disY = e.clientY - oDiv.offsetTop;
        document.onmousemove = function(e){
          let l = e.clientX - disX;
          let t = e.clientY - disY;
          if(l < 0)l=0;
          if(App.value.form[el.dataset.pre+'_size']/2 + l > 320)l=320-(App.value.form[el.dataset.pre+'_size']/2);
          if(t<0)t=0;
          if(App.value.form[el.dataset.pre+'_size']/2 + t > 504)t=504-(App.value.form[el.dataset.pre+'_size']/2);
          oDiv.style.left = l + "px";
          oDiv.style.top = t + "px";
          App.value.form[el.dataset.pre+'_left']=l*2
          App.value.form[el.dataset.pre+'_top']=t*2
        }
        document.onmouseup = function(){
          document.onmousemove = null;
          document.onmouseup = null;
        };
        return false;
      };
    }
  },
  data() {
    return {
      that:this,
      form:this.modelVal,
    }
  },
  computed:{
    qrcodeStyle(){
      let left = this.form.qrcode_left/2;
      let top = this.form.qrcode_top/2;
      let size = this.form.qrcode_size/2;
      if(!size)return `display:none`;
      return `left:${left}px;top:${top}px;height:${size}px;width:${size}px`;
    },
    picStyle(){
      let left = this.form.pic_left/2;
      let top = this.form.pic_top/2;
      let size = this.form.pic_size/2;
      if(!size)return `display:none`;
      return `left:${left}px;top:${top}px;height:${size}px;width:${size}px`;
    },
    titleStyle(){
      let left = this.form.title_left/2;
      let top = this.form.title_top/2;
      let size = this.form.title_size/2;
      let color = this.form.title_color;
      if(!size)return `display:none`;
      return `left:${left}px;top:${top}px;font-size:${size}px;color:${color}`;
    }
  },
  methods: {
    clearCache(){
      this.$api.plugin.shop.goodsPosterClearCache({id:this.form.id}).then(()=>{
        this.$message.success("操作成功");
      })
    },
    formSubmit(){
      this.$emit("submit")
    },
    qrcodeDrag(e){
      e.preventDefault();
      this.form.qrcode_top = e.offsetX*2;
      this.form.qrcode_left = e.offsetY*2;
      console.log(this.form,e.offsetX*2);
    }
  }

}
</script>

<style scoped>
.poster-pic,
.poster-qrcode,
.poster-title{
  position: absolute;
  white-space: nowrap;
}

.poster-pic,
.poster-qrcode{
  color: #f8f8f8;
  background-color: #5D60F6
}

.poster-pic{
  height: 50px;
  width: 50px;
}
.poster-qrcode{
  height: 100px;
  width: 100px;
}
.poster-con{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: 320px;
  height: 504px;
  overflow: hidden;
}
</style>