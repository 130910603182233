<template>
  <div>
    <el-row :gutter="40">
      <el-col :span="12">
        <el-form label-width="6rem">
          <el-form-item label="所属分类">
            <el-select v-model="form.cate_id" placeholder="请选择">
              <el-option
                  v-for="item in cateOptions"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属商家">
            <y_choose_merchant :merchant_id="form.merchant_id" @change="chooseM"></y_choose_merchant>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="商品描述">
            <el-input v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="商品图片">
            <y_upload_img v-model="form.pic"></y_upload_img>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form label-width="8rem">
          <el-form-item label="商品类型">
            <el-radio-group v-model="form.type">
              <el-radio :label="0">核销商品</el-radio>
              <el-radio :label="1">卡密商品</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="售价">
            <el-input-number v-model="form.price" size="mini"></el-input-number>元
            库存数量 <el-input-number :disabled="form.type === 1" v-model="form.stock" size="mini"></el-input-number>
            原价 <el-input-number v-model="form.original_fee" size="mini"></el-input-number>元
          </el-form-item>
          <el-form-item label="每人限购">
            <el-input-number v-model="form.buy_limit" size="mini"></el-input-number>

            直推奖励<el-input-number v-model="form.invite_award" size="mini"></el-input-number>元
            间推奖励<el-input-number v-model="form.invite_2_award" size="mini"></el-input-number>元
          </el-form-item>
          <el-form-item label="购买按钮文本">
            <el-input style="width: 20rem" v-model="form.buy_btn_txt"></el-input>
          </el-form-item>
          <template v-if="form.type === 0">
          <el-form-item label="核销次数">
            <el-input-number v-model="form.total_use"></el-input-number>
          </el-form-item>
          <el-form-item label="核销限制">
            <el-input-number v-model="form.use_limit"></el-input-number>天(两次核销间隔期限)
          </el-form-item>
          <el-form-item label="使用门栏">
            <el-input v-model="form.tips"></el-input>
          </el-form-item>
          <el-form-item label="使用期限">
            <el-date-picker
                v-model="range"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          </template>
          <el-form-item label="上架">
            <el-switch v-model="form.status"></el-switch>
          </el-form-item>
          <el-form-item label="首页显示">
            <el-switch v-model="form.index_show"></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" @click="submit">保存</el-button>
            <el-button size="mini" @click="$router.back()">返回列表</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload_img";
import y_choose_merchant from "@/components/y_choose_merchant";
export default {
  name: "basic",
  components:{
    y_upload_img,
    y_choose_merchant,
  },
  model:{
    prop:"modelVal",
    event:'change'
  },
  props:{
    modelVal:{
      type:Object,
      default(){
        return{
          cate_id:0,
          merchant_id:0,
          title:"",
          desc:"",
          pic:"",
          price:0,
          original_fee:0,
          type:0,
          stock:0,
          buy_limit:0,
          invite_award:0,
          invite_2_award:0,
          buy_btn_txt:"",

          status:false,
          index_show:false,
        }
      }
    },
  },
  watch:{
    modelVal(){
      this.range = [
        this.modelVal.time_start,
        this.modelVal.time_end,
      ]
      this.form = this.modelVal;
    }
  },
  data(){
    return{
      form:this.modelVal,
      cateOptions:[],
      range:[],
    }
  },
  mounted() {
    this.loadCate();
  },
  methods:{
    loadCate(){
      this.$api.plugin.shop.cateSearch().then(res=>{
        res.list.unshift({id:0,title:"未选择"})
        this.cateOptions = res.list;
      })
    },
    chooseM(e){
      this.form.merchant_id = e;
    },
    submit(){
      this.form.time_start = this.range[0];
      this.form.time_end = this.range[1];
      this.$emit("submit")
    },
  }
}
</script>

<style scoped>

</style>